/**
 * List of UIDs we frequently use in config JSON files.
 */
export enum UidList {
  referralCode = 'referral-code',
  aarp = 'aarp-discount',
  audio = 'audio',
  bookship = 'bookship', // Used for workbook shipping product
  certDelivery = 'shipping',
  certSubmission = 'cert-submission',
  coupon = 'coupon', // Used for coupon field
  couponDiscount = 'discount', // There's also rsaDiscount
  course = 'course',
  courseBundle = 'course-bundle',
  driverRecord = 'driver-record',
  courseAddOn = 'course-addon',
  /**
   * noop is used for products that don't need any operation done, such as pricing added when we merge APIs
   * E.g. If a course has 2 practice test products, a paid and a free product. The paid product should have
   * uid practice-test, while the free product should have uid noop to prevent it from getting the paid product's
   * pricing merged in.
   */
  noop = 'noop',
  practiceTest = 'practice-test',
  processingFee = 'processing-fee',
  rsaDiscount = 'rsa-discount',
  rsaOfferCheckbox = 'rsa-offer-checkbox',
  uhcDiscount = 'uhc-discount',
  video = 'video',
  voucher = 'voucher',
  upsell = 'upsell',
}
