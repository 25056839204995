import { DOCUMENT } from '@angular/common';
import type { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import type { Observable } from 'rxjs';
import { TimeoutError, throwError } from 'rxjs';
import { catchError, concatMap, map, take, tap, timeout } from 'rxjs/operators';

import type { FormGroup } from '@angular/forms';
import {
  ErrorTransformingService,
  IterableSubscribeService,
  PrepaidCodeService,
  RsaClaimStatusService,
  SegmentTrackingService,
  XccEnvironment,
  XgritApiService,
} from '@xcc-client/services';
import { AdobeAnalyticService } from '@xcc-client/services/lib/adobe-analytics/adobe-analytics.service';
import { AmbassadorRecordConversionService } from '@xcc-client/services/lib/ambassador-record-conversion/ambassador-record-conversion.service';
import { ScriptInjectionService } from '@xcc-client/services/lib/mntn-script-loader.service';
import { ShoppingCartService } from '@xcc-client/shared/components/shopping-cart/shopping-cart.service';
import { XccParentAccountPanelService } from '@xcc-client/shared/components/xcc-parent-account-panel/xcc-parent-account-panel/xcc-parent-account-panel.service';
import { XccPasswordChangeService } from '@xcc-client/shared/components/xcc-password-change/xcc-password-change/xcc-password-change.service';
import { XccStudentAccountPanelService } from '@xcc-client/shared/components/xcc-student-account-panel/xcc-student-account-panel/xcc-student-account-panel.service';
import type {
  CreatePaymentMethodResponse,
  ErrorList,
  LineItemList,
  OrderCompletedParams,
  OrderCompletedProduct,
  Product,
  PurchaseAnonymousParams,
  PurchaseAnonymousResponse,
  PurchaseReceiptPriceParams,
  PurchaseReceiptStatusResponse,
  PurchaseSuccessful,
  Referral,
  Subscription,
  SubscriptionClaimedParams,
  VideoPurchaseAnonymousParams,
  XgritPricingResponse,
  XgritPricingSuccessful,
  XgritQueryParams,
} from '@xcc-models';
import { Brand, PaymentProvider, ProductTypes, UidList, XccWindow } from '@xcc-models';
import { PaymentStripeService } from './payment-stripe.service';
import { ReCaptchaTokenService } from './recaptcha.service';

declare let saq: any;
@Injectable({
  providedIn: 'root',
})
export class XgritPurchaseService {
  private paymentMethodId: string;
  private productIdList: string[];
  private couponCodeList: string[];
  private bundleCouponCodeList: string[];
  private referralParams: string[];
  private couponIdList: string[];
  private cartTotal: number;
  private cartProducts: Product[];
  private isCdiLoadedAndClaimed: boolean;
  private queryParams: XgritQueryParams;
  useParentForm = false;
  isPrepaidCode: boolean;
  prepaidCode: string;
  productId: string;
  private isRecordConversionEnabled: boolean;

  get studentForm(): FormGroup {
    if (this.useParentForm) {
      return this.ppfService.formGroup;
    }
    return this.studentAccountFormService.formGroup;
  }

  constructor(
    public readonly shoppingCartService: ShoppingCartService,
    private readonly errorTransformingService: ErrorTransformingService,
    private readonly paymentStripeService: PaymentStripeService,
    private readonly iterableService: IterableSubscribeService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly rsaClaimStatusService: RsaClaimStatusService,
    private readonly segmentService: SegmentTrackingService,
    private readonly studentAccountFormService: XccStudentAccountPanelService,
    private readonly adobeAnalyticsService: AdobeAnalyticService,
    private readonly ppfService: XccParentAccountPanelService,
    private readonly xgritApiService: XgritApiService,
    private readonly xgritPasswordChangeService: XccPasswordChangeService,
    private readonly prepaidCodeService: PrepaidCodeService,
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject('window') private readonly window: XccWindow,
    @Inject('xccEnv') readonly xccEnv: XccEnvironment,
    private readonly reCaptchaTokenService: ReCaptchaTokenService,
    private readonly ambassadorRecordConversionService: AmbassadorRecordConversionService,
    private readonly scriptInjectionService: ScriptInjectionService,
  ) {
    this.route.queryParams.subscribe((queryParams: XgritQueryParams) => {
      this.couponCodeList = [queryParams.coupon, queryParams.crohc];
      this.bundleCouponCodeList = [queryParams.bundleCoupon, queryParams.crohc];
      this.productId = queryParams.productId;
      this.queryParams = queryParams;
      this.referralParams = [queryParams.campaignid, queryParams.mbsy];
    });
    this.useParentForm =
      this.window.xccConfig.pageConfig.wizardSteps.filter((step) =>
        step.components.find(
          (component) =>
            component.className === 'ParentPurchasePanelComponent' ||
            component.className === 'XccParentAccountPanelComponent',
        ),
      ).length > 0;
    this.rsaClaimStatusService.isCdiLoadedAndClaimed.subscribe((isLoadedAndClaimed: boolean) => {
      this.isCdiLoadedAndClaimed = isLoadedAndClaimed;
    });
    this.prepaidCodeService.currentCodeStatus.subscribe((value) => {
      this.isPrepaidCode = value;
    });
    this.ambassadorRecordConversionService.isRecordConversionEnabled.subscribe((status: boolean) => {
      this.isRecordConversionEnabled = status;
    });
  }

  onXgritPurchaseSuccess = (response: PurchaseSuccessful): void => {
    // Ambassador Conversion
    if (this.isRecordConversionEnabled) this.ambassadorRecordConversionService.recordConversion(response);
    const { resetCode, username } = response.isTeacher ? response.teacher : response.user;

    // MNTN Tracking Script Injection - Only on ACE DRV
    this.xccEnv.brand.toUpperCase() == Brand.ACE && this.scriptInjectionService.injectTrackingScript(response.total);

    this.xgritPasswordChangeService.setResetCode(resetCode);
    this.xgritPasswordChangeService.setUsername(username);
    // 'Identify' segment event must be called after 'Order Completed' event to allow the userId
    // parameter to be populated
    this.callIdentifyEvent(response);
    this.trackOrderCompleted(response);
    this.setSsoCookie(response);
    if (this.xccEnv.brand.toUpperCase() == Brand.AA || this.xccEnv.brand.toUpperCase() == Brand.ACE) {
      this.iterableService.updateUserPurchase(response).subscribe();
    } else {
      this.iterableService
        .trackPurchase(response)
        .pipe(
          concatMap(() => this.iterableService.updateUserPurchase(response)),
          take(1),
        )
        .subscribe();
    }
  };

  xgritPaymentFlow = (paymentMethodResponse: CreatePaymentMethodResponse): Observable<PurchaseAnonymousResponse> => {
    this.paymentMethodId = paymentMethodResponse.paymentMethod?.id;
    this.reCaptchaTokenService.setCatpchaToken(paymentMethodResponse.authCheck);

    return this.shoppingCartService.productsAsArray.pipe(
      map(this.pluckProductIds),
      concatMap(this.getPurchasePricing),
      map(this.buildXgritParams),
      concatMap(this.purchase),
      tap(this.conditionallyEnrollRsa),
      timeout(20000),
      catchError(this.setErrorMessage),
      take(1),
    );
  };

  xgritPrepaidFlow = (authCheck: string): Observable<PurchaseAnonymousResponse> => {
    this.reCaptchaTokenService.setCatpchaToken(authCheck);
    return this.shoppingCartService.productsAsArray.pipe(
      map(this.pluckProductIds),
      concatMap(this.getPrepaidPricing),
      map(this.buildPrepaidParams),
      concatMap(this.prepaidPurchase),
      timeout(20000),
      catchError(this.setErrorMessage),
      take(1),
    );
  };

  /**
   * Execute the purchase.
   *
   * Video upsell is a completely separate course with its own product IDs. Because of this,
   * ifa  video upsell is in the user's cart, we'll make a call to POST:/xgrit/purchase-video. This
   * endpoint will take care of replacing all the productIds with the corresponding productIds of
   * the video course. After that, it will execute the purchase.
   */
  private purchase = (params: PurchaseAnonymousParams) => {
    const videoUpsellProduct = this.cartProducts.find((item) => item.uid === UidList.video);
    if (videoUpsellProduct) {
      const videoParams: VideoPurchaseAnonymousParams = {
        ...params,
        videoCourseId: videoUpsellProduct.videoCourseId,
      };
      return this.xgritApiService.makeVideoPurchase(videoParams);
    }

    return this.xgritApiService.makePurchase(params);
  };

  private prepaidPurchase = (params: PurchaseAnonymousParams) => {
    return this.xgritApiService.makePurchase(params);
  };

  public setSsoCookie = (response: PurchaseSuccessful): void => {
    const domain = this.xccEnv.domain;
    if (response?.user.sessionToken) {
      this.document.cookie = `xgritsession=${response.user.sessionToken}; domain=${domain};`;
    }
  };

  /**
   * Conditionally enroll the user in RSA if they have claimed RSA.
   */
  private conditionallyEnrollRsa = (response: PurchaseSuccessful) => {
    if (response.subscription?.isSuccess) {
      const subscriptionClaimedParams: SubscriptionClaimedParams = {
        platform: 'Web',
        product_name: `${this.xccEnv.brand.toUpperCase()} RSA`,
        type: 'RSA',
      };
      if (this.isCdiLoadedAndClaimed) {
        this.segmentService.callAnalyticsMethod('track', 'Subscription Claimed', subscriptionClaimedParams).subscribe();
      }
    }
  };

  private setErrorMessage = (errorResponse: HttpErrorResponse | TimeoutError): Observable<never> => {
    if (errorResponse instanceof TimeoutError) {
      this.paymentStripeService.errorMessage = 'An error occurred while processing your request. Please try again.';
      return throwError(errorResponse.message);
    }
    console.log(errorResponse);
    const { error } = errorResponse;
    const errorList: ErrorList[] = error.errorList ? error.errorList : error.message;
    let errorMessages = '';
    //check if value returned is an array

    if (Array.isArray(errorList) && errorList.length !== 0) {
      errorMessages = errorList.map((err) => this.errorTransformingService.transformXgritError(err)).join(' ');
    } else {
      console.error('Error list is not an array', errorList);
      const rawError = { code: error.code, message: error.message };
      errorMessages = this.errorTransformingService.transformXgritError(rawError);
    }
    if (this.isPrepaidCode) {
      if (errorMessages.includes('Calculated charge amount')) {
        errorMessages = 'Invalid Prepaid Code. Please Enter Valid Code';
      }
    } else {
      this.paymentStripeService.errorMessage = errorMessages;
    }
    this.adobeAnalyticsService.logError(errorList);
    return throwError(errorMessages);
  };

  /**
   * Grab the product IDs of all products in the cart.
   * Products without a product ID will be skipped. Keep in mind there will be
   * products that don't have a product ID, such as default products like RSA discount,
   * which isn't a product and is simply used to display the discounted price to the user.
   */
  private pluckProductIds = (products: Product[]): string[] => {
    this.cartProducts = products;
    const prepaidProductIds = products.filter((product) => product.productId).map((product) => product.productId);

    if (this.isPrepaidCode) {
      this.productIdList = prepaidProductIds;
    }
    return prepaidProductIds;
  };

  private buildXgritParams = (pricingResponse: XgritPricingResponse): PurchaseAnonymousParams => {
    this.cartTotal = (pricingResponse as XgritPricingSuccessful).total;
    this.setCouponIdList(pricingResponse);

    const optiState = this.window.optimizely?.get && this.window.optimizely.get('state');
    let expName;
    let expId;
    let variationName;
    let variationId;
    if (optiState) {
      const optiData = optiState.getExperimentStates();
      const optiMeta = Object.keys(optiData);
      const expInfo: any = optiData[optiMeta[0]]; // eslint-disable-line @typescript-eslint/no-explicit-any
      expName = expInfo?.experimentName;
      expId = expInfo?.id;
      variationName = expInfo?.variation?.name;
      variationId = expInfo?.variation?.id;
    }

    const { defaultProducts } = this.window.xccConfig.productConfig;
    let subscription: Subscription;
    if (this.isCdiLoadedAndClaimed) {
      const rsaDiscount = defaultProducts.find((item) => item.uid === UidList.rsaDiscount);
      subscription = {
        program: 'RSA',
        programOptions: {
          vendor: 'Allstate',
          planId: rsaDiscount?.planId ? rsaDiscount.planId : '',
        },
      };
    }
    const payLoadParams: PurchaseAnonymousParams = {
      accountType: 'student',
      subscription,
      productIdList: this.productIdList,
      // NOTE: couponCodeList is only supported in the pricing call, not the purchase call
      couponIdList: this.couponIdList,
      chargeAmount: this.cartTotal,
      token: this.paymentMethodId,
      // email: `test+${Math.floor(100000 + Math.random() * 900000)}@aceable.com`,
      email: this.studentForm.get('email').value,
      password: this.studentForm.get('password')?.value,
      emailPassthru: {
        purchasedRSA: this.isCdiLoadedAndClaimed,
      },
      paydataPassthru: {
        experiment_name: expName,
        experiment_id: expId,
        variation_name: variationName,
        variation_id: variationId,
        is_rsa_attached: this.isCdiLoadedAndClaimed,
      },
      firstName: this.getName('first'),
      lastName: this.getName('last'),
      url: this.window.location.href,
      authCheck: this.reCaptchaTokenService.getCaptchaToken(),
      provider: PaymentProvider.STRIPE22,
      returnUrl: this.window.location.origin + '/reply?productId=' + this.productId,
      referral: this.buildReferralParam(),
    };
    if (this.useParentForm && this.studentForm.get('teacherEmail')) {
      payLoadParams.teacherEmail = this.studentForm.get('teacherEmail').value.trim();
      payLoadParams.accountType = 'parent';
    }
    return payLoadParams;
  };

  private buildPrepaidParams = (pricingResponse: XgritPricingResponse): PurchaseAnonymousParams => {
    this.setCouponIdList(pricingResponse);
    return {
      accountType: this.useParentForm ? 'parent' : 'student',
      productIdList: [this.productId],
      // NOTE: couponCodeList is only supported in the pricing call, not the purchase call
      couponIdList: this.couponIdList,
      chargeAmount: 0,
      token: '',
      email: this.studentForm.get('email').value,
      password: this.studentForm.get('password')?.value,
      firstName: this.getName('first'),
      lastName: this.getName('last'),
      url: this.window.location.href,
      authCheck: this.reCaptchaTokenService.getCaptchaToken(),
      provider: PaymentProvider.FREE,
      returnUrl: this.window.location.href,
      referral: this.buildReferralParam(),
    };
  };

  private getName(section: string): string {
    if (this.useParentForm) {
      const wholeName = this.studentForm.get('studentName').value.trim().split(' ');
      const name = {
        first: wholeName[0],
        last: wholeName.length > 1 ? wholeName[wholeName.length - 1] : '',
      };
      return name[section];
    }
    if (section === 'first') {
      return this.studentForm.get('firstName').value.trim();
    }
    return this.studentForm.get('lastName')?.value.trim() ?? '';
  }

  private getPurchasePricing = (productIdList: string[]): Observable<XgritPricingResponse> => {
    const params: PurchaseReceiptPriceParams = {
      productIdList,
      couponCodeList: this.shoppingCartService.concatCouponList(),
    };

    this.productIdList = productIdList;

    return this.xgritApiService.getPurchaseReceiptPrice(params);
  };

  private getPrepaidPricing = (productIdList: string[]): Observable<XgritPricingResponse> => {
    const params: PurchaseReceiptPriceParams = {
      productIdList,
      couponCodeList: this.shoppingCartService.concatCouponList(),
    };
    /*
     * Case: When cart has a voucher
     * Consider that couponCodeList has undefined values on the array so we need
     * to filter to get just the one with no undefined if it is the case
     */
    const cartHasVoucher = this.cartProducts.find((coupon) => coupon.uid === UidList.voucher);

    const oneHundredOffCoupon = this.cartProducts.find((prod) => prod.uid === UidList.coupon && prod.offPercent === 1);

    if (cartHasVoucher) {
      const storedCoupons: string[] = this.couponCodeList.filter((coupon) => coupon !== undefined);
      const coupons: string[] = [cartHasVoucher.label, ...storedCoupons];

      params.couponCodeList = coupons;
    }

    if (oneHundredOffCoupon) {
      const storedCoupons: string[] = this.couponCodeList.filter((coupon) => coupon !== undefined);
      const coupons: string[] = [oneHundredOffCoupon.label, ...storedCoupons];

      params.couponCodeList = coupons;
    }

    this.productIdList = productIdList;

    return this.xgritApiService.getPurchaseReceiptPrice(params);
  };

  private setCouponIdList = (pricingResponse: XgritPricingResponse): void => {
    const { lineItemList } = pricingResponse as XgritPricingSuccessful;
    const productsWithCoupon = lineItemList
      .filter((item: LineItemList) => item.couponList?.length > 0)
      .map(({ couponList }) => couponList)
      .reduce((acc, val) => acc.concat(val), []); // Flatten array of arrays
    const queryCoupons = [this.queryParams.coupon, this.queryParams.bundleCoupon, this.queryParams.crohc];

    const hiddenCouponId = productsWithCoupon.find(
      ({ code }) => code === this.queryParams.coupon || code === this.queryParams.bundleCoupon,
    )?._id;
    const croCouponId = productsWithCoupon.find(({ code }) => code === this.queryParams.crohc)?._id;
    const nonQueryParamCoupons = productsWithCoupon
      .filter(({ code }) => !queryCoupons.includes(code))
      .map(({ _id }) => _id); // Non-query param code, such as vouchers or user applied coupons

    const uniqueNonQueryParamCoupons = Array.from(new Set(nonQueryParamCoupons));

    // Set the coupon IDs so the xgritApiService doesn't have to do an extra lookup
    this.xgritApiService.couponCodeId = hiddenCouponId;

    this.couponIdList = [hiddenCouponId, croCouponId, ...uniqueNonQueryParamCoupons].filter((n) => n); // Filter out undefined values if any
  };

  public trackOrderCompleted = (response: PurchaseSuccessful, receiptStatus?: PurchaseReceiptStatusResponse): void => {
    // Add purchaseSuccess=true to the URL when purchase is completed
    if (this.xccEnv.addPurchaseQueryParam) this.addPurchaseQueryParam();

    const { lineItemList } = response;
    /**
     * The `base_sales` is the price of the main product being sold, excluding upsells, which is
     * typically a course, bundle, or exam. The main product being sold is typically the ID that
     * matches the `productId` query param value.
     *
     * However, there are cases to consider:
     * - Main product is a course but gets replaced by a bundle (e.g. IDS & DEC FL.DE)
     * - Main product is not a course/bundle/exam (i.e. practice test, driver record), but gets
     *   replaced by an upsell (e.g.IDS.CA.PT).
     */
    const mainProductItem =
      lineItemList.length === 1
        ? lineItemList[0]
        : lineItemList.find(
            (item) =>
              item.product._id === this.productId ||
              item.product.type === ProductTypes.COURSE ||
              item.product.type === ProductTypes.BUNDLE ||
              item.product.type === ProductTypes.EXAM,
          );
    const products: OrderCompletedProduct[] = [];

    lineItemList.forEach((item) => {
      products.push({
        brand: item.product.brandId,
        category: item.product.type,
        coupon: this.couponCodeList[0],
        course_id: item.courseId,
        course_segment: item.product.filter.type,
        course_state: item.product.filter.state,
        discount: item.couponList.length > 0 ? parseFloat(item.couponList[0].discountAmount.toFixed(2)) : 0,
        name: item.title,
        price: item.product.pricing.current,
        product_id: item.product._id,
        product_name: item.title,
        quantity: 1,
      });
    });

    const segmentParams: OrderCompletedParams = {
      affiliation: 'STRIPE',
      base_sales: parseFloat(mainProductItem.chargeAmount.toFixed(2)),
      currency: 'USD',
      discount: parseFloat(response.discountAmount.toFixed(2)),
      gross_sales: response.total,
      order_id: response.transactionId ? response.transactionId : receiptStatus.receipt.vendorTransactionId,
      platform: 'Web',
      products,
      revenue: response.total,
      total: response.total,
      user_type: 'student',
      value: response.total,
    };

    const cookieName = 'CJEVENTID';
    const hasCjCookie = this.document.cookie.includes(cookieName);
    if (hasCjCookie) {
      const allCookies = this.document.cookie.split('; ');
      const cjCookie = allCookies.find((item) => item.includes(cookieName)); // CJEVENTID=some_id
      const cjCookieValue = cjCookie ? cjCookie.split('=')[1] : null;

      if (cjCookieValue) {
        segmentParams.cj_event = cjCookieValue;
      }
    }

    this.segmentService.callAnalyticsMethod('track', 'Order Completed', segmentParams, {
      traits: {
        email: response.user.username,
      },
    });
  };

  public callIdentifyEvent = (response: PurchaseSuccessful): void => {
    const identifyParams = {
      created_date: response.createdAt,
      email: response.user.username,
      first_name: response.user.firstName,
    };

    this.segmentService.callIdentifyMethod(response.user._id, identifyParams);
  };

  /**
   * Adds `&purchaseSuccess=true` to the URL without navigating or
   * reloading the page.
   *
   * this allow us to track LinkedIn conversions.
   */
  private addPurchaseQueryParam = () =>
    this.router.navigate(['.'], {
      relativeTo: this.route.firstChild,
      queryParams: { purchaseSuccess: true },
      queryParamsHandling: 'merge',
    });

  /**
   * Builds a referral object based on the referral parameters.
   * @returns The referral object with campaignId and shortCode properties, or undefined if the referralParams are not valid.
   */
  private buildReferralParam = (): Referral => {
    if (this.referralParams.length === 2 && this.referralParams[0] && this.referralParams[1]) {
      return {
        campaignId: this.referralParams[0],
        shortCode: this.referralParams[1],
      };
    }
    return undefined;
  };
}
